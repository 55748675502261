<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="bomsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">BILL OF MATERIALS</h1>
                        <v-btn rounded color="primary" @click="openFindBOM">
                            <v-icon class="mr-1">
                                mdi-database-search
                            </v-icon>
                            FIND
                        </v-btn>
                        <v-btn
                            v-if="projectRoute"
                            rounded
                            color="primary"
                            @click="openSelectWO"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADER FILTER-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.index`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.code`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.code }}</p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0" v-if="item.creator">
                        {{ item.creator.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0" v-if="item.project">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            formatDate(
                                $router.currentRoute.name == 'closed-boms'
                                    ? item.closedDate.seconds ||
                                          item.closedDate._seconds
                                    : item.createdOn.seconds ||
                                          item.createdOn._seconds
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.notes }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openProcessBOM(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <div v-if="item.partialsRejectionHistory">
                        <v-menu rounded offset-y>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    depressed
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    @click.stop="openRejected(item)"
                                    v-if="item.partialsRejectionHistory"
                                >
                                    <v-list-item-title>
                                        Show Partials Rejection Reasons
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS BOM-->
        <v-dialog
            v-model="processBOM"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessBOM
                v-if="processBOM"
                :bom="selectedBOM"
                :process="processName"
                @close="closeProcessBOM"
                @removeBOM="removeBOM"
                @rejectBOM="rejectBOM"
                @approve="approve"
                @closeBOM="closeBOM"
            />
        </v-dialog>
        <!--SELECT WO-->
        <v-dialog
            v-model="selectWO"
            :retain-focus="false"
            persistent
            max-width="400px"
        >
            <SelectWO
                v-if="selectWO"
                :projectId="projectId"
                @close="closeSelectWO"
                @addWO="addWO"
                @openNewBomForm="openNewBomForm"
            />
        </v-dialog>
        <!--BOM FORM-->
        <v-dialog v-model="bomForm" persistent max-width="80%">
            <BOMForm
                v-if="bomForm"
                :workOrder="selectedWO"
                :createForm="createForm"
                :bom="selectedBom"
                :originalBom="originalBom"
                @close="closeBomForm"
                @reload="reload"
            />
        </v-dialog>
        <!--FIND BOM-->
        <v-dialog
            v-model="findBOM"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindBOM v-if="findBOM" @addBOM="addBOM" @close="closeFindBOM" />
        </v-dialog>
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectionTable
                v-if="rejectionTable"
                :items="selectedBOM.partialsRejectionHistory"
                @closeDialog="closeRejected"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'ApprovalBOM',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        ProcessBOM: () => import('@/components/WorkOrders/ProcessBOM.vue'),
        SelectWO: () => import('@/components/WorkOrders/SelectWO.vue'),
        BOMForm: () => import('@/components/WorkOrders/BOMForm.vue'),
        FindBOM: () => import('@/components/WorkOrders/FindBOM.vue'),
        RejectionTable: () =>
            import('@/components/WorkOrders/RejectionBOMTable.vue'),
    },
    data: () => ({
        selectedWO: {},
        selectWO: false,
        selectedBOM: undefined,
        processBOM: false,
        processName: 'Approve Bill Of Material',
        projectToFilter: undefined,
        statusToFilter: undefined,
        loading: false,
        boms: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 170,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            sent: '#FF9800',
            approved: '#27BC4F',
            production: '#FFC000',
            rejected: 'red',
            closed: '#ff9900',
            inProgress: '#9E9FA0',
            deleted: 'black',
        },
        projects: [],
        users: [],
        projectRoute: false,
        createForm: false,
        bomForm: false,
        selectedBom: {},
        originalBom: {},
        findBOM: false,
        resourceId: undefined,
        rejectionTable: false,
    }),
    computed: {
        ...mapState(['notificationResource']),
        ...mapState(['bomUpdates']),
        bomsFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterBOM)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (conditions.length > 0) {
                return this.boms.filter(bom => {
                    return conditions.every(condition => {
                        return condition(bom)
                    })
                })
            }
            this.sort()
            return this.boms
        },
    },
    watch: {
        $route: 'handleRouteChange',
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
        bomUpdates: function(data) {
            if (data) {
                this.UpdateBom(data)
                this.setBomUpdates(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.projects = await API.getLiteProjects({
                all: true,
                closing: true,
            })
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.resourceId = this.$route.query.resourceId
            await this.getBoms()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
            SocketioService.joinRoom(`${this.companyId}-BOM`)
            SocketioService.leaveRoom(`${this.companyId}-WO`)
            SocketioService.leaveRoom(`${this.companyId}-INVOICE`)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        ...mapActions(['setBomUpdates']),
        ...mapGetters(['getBomUpdates']),
        closeBOM() {
            this.boms = this.boms.filter(bom => bom.id != this.selectedBOM.id)
            this.selectedBom = {}
            this.processBOM = false
        },
        async handleRouteChange() {
            try {
                this.loading = true
                this.boms = []
                await this.getBoms()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getBoms() {
            try {
                if (
                    this.$router.currentRoute.name ==
                        'project-bill-of-materials' ||
                    this.$router.currentRoute.name ==
                        'archive-project-bill-of-materials'
                ) {
                    this.projectRoute = true
                    this.boms = await API.getBOMsChangelog({
                        projectId: this.projectId,
                    })
                } else if (this.$router.currentRoute.name == 'closed-boms') {
                    this.boms = await API.getBOMsChangelog({
                        status: ['closed'],
                    })
                } else {
                    this.boms = await API.getBOMsChangelog({
                        status: ['inProgress'],
                    })
                }
                for (const bom of this.boms) {
                    //ProjectInfo
                    const projectIndex = this.projects.findIndex(
                        p => p.id === bom.projectId
                    )
                    if (projectIndex > -1) {
                        bom.project = this.projects[projectIndex]
                    }
                    //UsersInfo
                    if (bom.project) {
                        const managerIndex = this.users.findIndex(
                            u => u.id === bom.project.projectManager
                        )
                        if (managerIndex > -1) {
                            bom.projectManager = this.users[managerIndex]
                        }
                    }
                    const creatorIndex = this.users.findIndex(
                        u => u.id === bom.createdBy
                    )
                    if (creatorIndex > -1) {
                        bom.creator = this.users[creatorIndex]
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async reload(boms) {
            try {
                this.loading = true
                for (const bom of boms) {
                    //ProjectInfo
                    const projectIndex = this.projects.findIndex(
                        p => p.id === bom.projectId
                    )
                    if (projectIndex > -1) {
                        bom.project = this.projects[projectIndex]
                    }
                    //UsersInfo
                    const managerIndex = this.users.findIndex(
                        u => u.id === bom.project.projectManager
                    )
                    if (managerIndex > -1) {
                        bom.projectManager = this.users[managerIndex]
                    }
                    const creatorIndex = this.users.findIndex(
                        u => u.id === bom.createdBy
                    )
                    if (creatorIndex > -1) {
                        bom.creator = this.users[creatorIndex]
                    }
                }
                this.boms = [...boms, ...this.boms]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openEditBomForm(item) {
            this.selectedBom = _.cloneDeep(item)
            this.originalBom = _.cloneDeep(item)
            this.createForm = false
            this.bomForm = true
        },
        closeBomForm() {
            this.selectedBom = {}
            this.originalBom = {}
            this.createForm = false
            this.bomForm = false
        },
        openNewBomForm() {
            this.createForm = true
            this.bomForm = true
        },
        addWO(workOrder) {
            this.selectedWO = workOrder
        },
        closeSelectWO() {
            this.selectWO = false
        },
        openSelectWO() {
            this.selectWO = true
        },
        rejectBOM(bom) {
            const index = this.boms.findIndex(b => b.id == bom.id)
            if (index >= 0) {
                this.boms.splice(index, 1, { ...this.boms[index], ...bom })
            }
            this.processBOM = false
        },
        removeBOM() {
            this.boms = this.boms.filter(bom => bom.id != this.selectedBOM.id)
            this.selectedBOM = {}
            this.processBOM = false
        },
        filterBOM(bom) {
            return bom.code.toLowerCase().includes(this.search.toLowerCase())
        },
        openProcessBOM(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.processBOM = true
        },
        closeProcessBOM(bom) {
            const bomIndex = this.boms.findIndex(
                b => b.code == this.selectedBOM.code
            )
            if (bomIndex > -1) {
                this.boms[bomIndex] = bom
            }
            this.boms = _.cloneDeep(this.boms)
            this.selectedBOM = {}
            this.processBOM = false
        },
        filterProject(bom) {
            return (
                bom.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                bom.project?.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterForStatus(bom) {
            if (bom.status) {
                return bom.status
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            } else {
                return false
            }
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        approve() {
            this.boms = this.boms.filter(bom => bom.id != this.selectedBOM.id)
            this.activateAlert = true
            this.alertMessage = 'BOM Successfully Approved'
            this.closeProcessBOM()
        },
        sort() {
            this.boms.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        openFindBOM() {
            this.findBOM = true
        },
        closeFindBOM() {
            this.findBOM = false
        },
        async addBOM(bom) {
            try {
                this.loading = true
                const index = this.boms.findIndex(b => b.id == bom.id)
                if (index >= 0) {
                    this.openProcessBOM(this.boms[index])
                } else {
                    bom.project = await API.getProject(bom.projectId)
                    bom.projectManager = this.users.find(
                        user => user.id == bom.project.projectManager
                    )
                    bom.creator = this.users.find(
                        user => user.id == bom.createdBy
                    )
                    bom.approvedBy = this.users.find(
                        user => user.id == bom.approvedBy
                    )
                    this.boms.splice(0, 0, bom)
                    this.openProcessBOM(bom)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.boms.find(b => b.id == this.resourceId)
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessBOM(resource)
                }
            }
        },
        async UpdateBom(bom) {
            try {
                const statusToUpdate =
                    this.$router.currentRoute.name ===
                        'project-bill-of-materials' ||
                    this.$router.currentRoute.name ===
                        'archive-project-bill-of-materials'
                        ? ['inProgress', 'approved', 'rejected', 'closed']
                        : this.$router.currentRoute.name === 'closed-boms'
                        ? ['closed']
                        : ['inProgress']

                const index = this.boms.findIndex(b => b.id == bom.bomId)
                if (index >= 0) {
                    if (statusToUpdate.includes(bom.status)) {
                        const updatedBom = {
                            ...this.boms[index],
                            ...bom,
                        }
                        this.boms.splice(index, 1, updatedBom)
                        if (this.selectedBOM.id == bom.bomId) {
                            this.selectedBOM = _.cloneDeep(this.boms[index])
                        }
                    } else {
                        this.boms = this.boms.filter(b => b.id != bom.bomId)
                    }
                } else if (statusToUpdate.includes(bom.status)) {
                    const newBom = await API.getBoms({
                        id: bom.bomId,
                    })
                    for (let bom of newBom) {
                        if (this.selectedBOM.id == bom.bomId) {
                            this.selectedBOM = _.cloneDeep(this.boms[index])
                        }
                        this.boms.push(bom)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },

        openRejected(bom) {
            this.selectedBOM = bom
            this.rejectionTable = true
        },

        closeRejected() {
            this.selectedBOM = {}
            this.rejectionTable = false
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
